<template>
  <v-col
    cols="12"
    lg="8"
    offset-md="2"
  >
    <v-card>
      <v-card-title>Upgrade Account</v-card-title>
      <v-card-text>
        <v-form>
          <v-row class="mt-0 pt-0">
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="referenceNo"
                label="Reference Number"
                outlined
                dense
                class="black--text"
                type="text"
              >
              <v-btn
                slot="append"
                color="info"
                small
                @click="getUpgradeCodes"
                :disabled="referenceNo == ''"
              >
                Get Code
              </v-btn>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="formFields.upgradeCode"
                :items="upgradeCodes"
                dense
                auto-select-first
                label="Upgrade To"
                :class="formErrorFields.upgradeCodeErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.upgradeCodeErrorMessage"
                outlined
                :disabled="upgradeCodes.length == 0"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="formFields.account"
                :items="accounts"
                dense
                clearable
                auto-select-first
                label="Account to Upgrade"
                :class="formErrorFields.requestToErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.requestToErrorMessage"
                outlined
                :disabled="formFields.upgradeCode == ''"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="success"
          :loading="loading"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-col>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mask } from 'vue-the-mask'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import storeState from '../store'

export default {
  directives: { mask },
  components: { snackbar },

  setup() {
    const loading = ref(false)
    const referenceNo = ref('')
    const accounts = ref([])
    const upgradeCodes = ref([])
    const upgradeCodes2 = ref([])
    const formFields = ref({
      upgradeCode: '',
      account: '',
    })
    const formErrorFields = ref({
      upgradeCodeErrorMessage: '',
      sponsorErrorMessage: '',
      requestToCodeErrorMessage: '',
      requestToErrorMessage: '',
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formFields.value = {
        upgradeCode: '',
        account: '',
      }
    }

    const parseAccount = entryDestination => {
      const user = storeState.state.user.info
      
      let shouldBeIn = []
      accounts.value = []
      for(const row of user.member_account) {
        switch(entryDestination) {
          // case 'PLATINUM':
          //   shouldBeIn = ['RESELLER', 'ECONOMY', 'PREMIUM', 'VIP MUNICIPAL', 'VIP PROVINCIAL']
          //   if(shouldBeIn.includes(row.entry_type)) {
          //     accounts.value.push({value: row.id, text: row.registration_code + ' - ' + row.entry_type})
          //   }
          //   break
          // case 'VIP PROVINCIAL':
          //   shouldBeIn = ['RESELLER', 'ECONOMY', 'PREMIUM', 'VIP MUNICIPAL']
          //   if(shouldBeIn.includes(row.entry_type)) {
          //     accounts.value.push({value: row.id, text: row.registration_code + ' - ' + row.entry_type})
          //   }
          //   break
          // case 'VIP MUNICIPAL':
          //   shouldBeIn = ['RESELLER', 'ECONOMY', 'PREMIUM']
          //   if(shouldBeIn.includes(row.entry_type)) {
          //     accounts.value.push({value: row.id, text: row.registration_code + ' - ' + row.entry_type})
          //   }
          //   break
          // case 'PREMIUM':
          //   shouldBeIn = ['RESELLER', 'ECONOMY']
          //   if(shouldBeIn.includes(row.entry_type)) {
          //     accounts.value.push({value: row.id, text: row.registration_code + ' - ' + row.entry_type})
          //   }
          case 'ECONOMY':
            shouldBeIn = ['RESELLER']
            if(shouldBeIn.includes(row.entry_type)) {
              accounts.value.push({value: row.id, text: row.registration_code + ' - ' + row.entry_type})
            }
            break
        }
      }

      formFields.value.account = user.member_account[0].id
    }


    const getUpgradeCodes = async () => {
      upgradeCodes2.value = []
      upgradeCodes.value = []
      await get(`${process.env.VUE_APP_URI}/api/member/upgrade/get-codes/${referenceNo.value}`, upgradeCodes2, null)
      for(const row of upgradeCodes2.value) {
        upgradeCodes.value.push({value: row.id, text: row.entry_type_destination});
      }
    } 

    const submit = async () => {
      const formData = formFields.value

      const uri = `${process.env.VUE_APP_URI}/api/member/upgrade`
      await store(uri, loading, formData, formErrorFields)
      if (responseMessageStatus.value === 'success') {
        reset()
        location.reload()
      }
    }

    watch(() => formFields.value.upgradeCode, val => {
      if(val != '') {
        const index = upgradeCodes.value.findIndex(row => row.value == val)
        const entry = upgradeCodes.value[index].text

        parseAccount(entry)
      }
    })

    return {
      referenceNo,
      upgradeCodes,
      upgradeCodes2,
      accounts,
      
      loading,
      formFields,
      formError,
      formErrorFields,
      responseMessage,
      responseMessageStatus,

      submit,
      getUpgradeCodes,
    }
  },
}
</script>
