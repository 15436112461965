
const state = {
  account_id: '',
};

const actions = {
  setId({commit}, user) {
    commit('account_id', user);
  },
}

const getters = {
  getId: state => state.account_id
};

const mutations = {
  account_id(state, data) {
    state.account_id = data;
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}