<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      lg="4"
    >
      <v-card :loading="loading" color="primary">
        <statistics-card-vertical
          color="info"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(stair_step)"
          stat-title="Total Stair Step Bonus"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="4"
      lg="4"
    >
      <v-card :loading="loading" color="success">
        <statistics-card-vertical
          color="success"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(global_pool)"
          stat-title="Total Global Pool Bonus"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="4"
      lg="4"
    >
      <v-card :loading="loading" color="info">
        <statistics-card-vertical
          color="info"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(profit_share)"
          stat-title="Total Profit Share"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrendingUp, mdiCurrencyUsd, mdiCheck, mdiChartTimelineVariant } from '@mdi/js'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

export default {
  props: {
    indirect: null,
    stair_step: null,
    global_pool: null,
    profit_share: null,
    loading: Boolean,
  },

  components: {
    StatisticsCardVertical,
  },
  setup() {
    return {
      mdiCurrencyUsd,
    }
  }
}
</script>

