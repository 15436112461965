<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner1  col-lg-6 col-sm-12 col-md-6">
      <v-card class="auth-card mt-0 pt-0">
        <v-card-title class="justify-center">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="250px"
              max-width="200px"
              alt="logo"
              contain
              class="me-3 d-flex"
            ></v-img>
          </router-link>
        </v-card-title>
        <v-card-text class="text-center">
          <p class="text-lg font-weight-semibold text--primary">
            Account Registration
          </p>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="py-0 my-0"
            >
              <v-radio-group
                v-model="formFields.membershipType"
                row
                :error-messages="formErrorFields.membershipTypeErrorMessage"
              >
                <v-radio
                  label="CPP"
                  value="cpp"
                  @click="formError().remove('membershipType', formErrorFields)"
                ></v-radio>
                <v-radio
                  label="MLM"
                  value="mlm"
                  @click="formError().remove('membershipType', formErrorFields)"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col
              cols="12"
              md="8"
              lg="8"
              class="pb-0 mb-0"
            >
              <v-autocomplete
                v-model="formFields.accountType"
                :items="['INDIVIDUAL','COOP','CORPORATION']"
                dense
                auto-select-first
                label="Account Type"
                :class="formErrorFields.accountTypeErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.accountTypeErrorMessage"
                outlined
              />
            </v-col>
          </v-row>

          <v-row v-if="formFields.accountType === 'INDIVIDUAL'">
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="formFields.firstname"
                label="First Name"
                maxlength="50"
                outlined
                dense
                type="text"
                :class="formErrorFields.firstnameErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.firstnameErrorMessage"
                @keydown="formError().remove('firstname', formErrorFields)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="formFields.middlename"
                label="Middle Name"
                outlined
                dense
                maxlength="50"
                type="text"
                :class="formErrorFields.middlenameErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.middlenameErrorMessage"
                @keydown="formError().remove('middlename', formErrorFields)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="formFields.lastname"
                label="Last Name"
                outlined
                dense
                maxlength="50"
                type="text"
                :class="formErrorFields.lastnameErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.lastnameErrorMessage"
                @keydown="formError().remove('lastname', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col
              cols="12"
              md="8"
              lg="8"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="formFields.businessName"
                label="Business Name"
                outlined
                dense
                type="text"
                maxlength="50"
                :class="formErrorFields.businessNameErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.businessNameErrorMessage"
                @keydown="formError().remove('businessName', formErrorFields)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="py-0 my-0"
              v-if="formFields.accountType === 'COOP'"
            >
              <v-text-field
                v-model="formFields.cdaRegistration"
                label="CDA Registration"
                outlined
                dense
                type="text"
                maxlength="25"
                :class="formErrorFields.cdaRegistrationErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.cdaRegistrationErrorMessage"
                @keydown="formError().remove('cdaRegistration', formErrorFields)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="py-0 my-0"
              v-else
            >
              <v-text-field
                v-model="formFields.dtiOrSecRegistration"
                label="DTI/SEC Registration"
                outlined
                dense
                type="text"
                maxlength="25"
                :class="formErrorFields.dtiOrSecRegistrationErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.dtiOrSecRegistrationErrorMessage"
                @keydown="formError().remove('dtiOrSecRegistration', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="6"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="formFields.email"
                label="Email Address"
                outlined
                dense
                type="email"
                maxlength="50"
                :class="formErrorFields.emailErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.emailErrorMessage"
                @keydown="formError().remove('email', formErrorFields)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="formFields.mobileNumber"
                v-mask="'09##-###-####'"
                label="Mobile Number"
                outlined
                dense
                type="text"
                :class="formErrorFields.mobileNumberErrorMessage.length > 0 ? 'mb-3' : 'mb-0 pb-0'"
                :error-messages="formErrorFields.mobileNumberErrorMessage"
                @keydown="formError().remove('mobileNumber', formErrorFields)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="formFields.phoneNumber"
                v-mask="'###-####-####'"
                label="Phone Number"
                outlined
                dense
                type="text"
                :class="formErrorFields.phoneNumberErrorMessage.length > 0 ? 'mb-3' : 'mb-0 pb-0'"
                :error-messages="formErrorFields.phoneNumberErrorMessage"
                @keydown="formError().remove('phoneNumber', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="pt-0 mt-0 pb-0 mb-0"
            >
              <v-autocomplete
                v-model="formFields.province"
                outlined
                dense
                clearable
                autocomplete="off"
                label="Province"
                item-text="province_name"
                item-value="id"
                :loading="provinceLoading"
                :items="provinces"
                :class="formErrorFields.provinceErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.provinceErrorMessage"
                @change="provinceWasChange"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="pt-0 mt-0 pb-0 mb-0"
            >
              <v-autocomplete
                v-model="formFields.city"
                outlined
                dense
                clearable
                autocomplete="off"
                label="City / Municipality"
                item-text="municipality_name"
                item-value="id"
                :loading="cityLoading"
                :items="cities"
                :class="formErrorFields.cityErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.cityErrorMessage"
                @change="cityWasChange"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="pt-0 mt-0 pb-0 mb-0"
            >
              <v-autocomplete
                v-model="formFields.barangay"
                outlined
                dense
                clearable
                autocomplete="off"
                label="Barangay"
                item-text="barangay_name"
                item-value="id"
                :loading="barangayLoading"
                :items="barangays"
                :class="formErrorFields.barangayErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.barangayErrorMessage"
                @change="formError().remove('barangay', formErrorFields)"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              class="pt-0 mt-0 pb-0 mb-0"
            >
              <!-- Address -->
              <v-text-field
                v-model="formFields.address"
                autocomplete="off"
                label="Address"
                outlined
                dense
                maxlength="150"
                :class="formErrorFields.addressErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.addressErrorMessage"
                @keydown="formError().remove('address', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="my-0 py-0"
            >
              <v-text-field
                v-model="formFields.username"
                label="Username"
                outlined
                dense
                type="text"
                maxlength="15"
                :class="formErrorFields.usernameErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.usernameErrorMessage"
                @keydown="formError().remove('username', formErrorFields)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="my-0 py-0"
            >
              <v-text-field
                v-model="formFields.password"
                label="Password"
                outlined
                dense
                type="password"
                maxlength="20"
                :class="formErrorFields.passwordErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.passwordErrorMessage"
                @keydown="formError().remove('password', formErrorFields)"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="my-0 py-0"
            >
              <v-text-field
                v-model="formFields.password_confirmation"
                label="Confirm Password"
                outlined
                dense
                type="password"
                maxlength="20"
                :class="formErrorFields.password_confirmationErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.password_confirmationErrorMessage"
                @keydown="formError().remove('password_confirmation', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col
              v-if="router.params.sponsor===undefined"
              cols="12"
              md="4"
              lg="4"
              class="my-0 py-0"
            >
              <v-text-field
                v-model="formFields.sponsor"
                label="Sponsor Code"
                outlined
                dense
                type="text"
                maxlength="10"
                :class="formErrorFields.sponsorErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.sponsorErrorMessage"
                @keydown="formError().remove('sponsor', formErrorFields)"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="4"
              lg="4"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="formFields.requestTo"
                :items="['STOCKIST','BRANCH','HEAD OFFICE']"
                dense
                auto-select-first
                label="Request to"
                :class="formErrorFields.requestToErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.requestToErrorMessage"
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
              lg="4"
              class="my-0 py-0"
              v-if="formFields.requestTo != 'HEAD OFFICE'"
            >
              <v-text-field
                v-model="formFields.requestToCode"
                :label="`${formFields.requestTo !== null ? formFields.requestTo : ''} CODE`"
                outlined
                dense
                type="text"
                maxlength="10"
                :class="formErrorFields.requestToCodeErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.requestToCodeErrorMessage"
                @keydown="formError().remove('requestToCode', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="justify-center">
            <v-col
              cols="12"
              md="4"
              lg="4"
              class="my-0 py-0"
            >
              <v-btn
                icon
                @click="getCaptcha"
              >
                <v-icon>{{ icons.mdiReload }}</v-icon>
              </v-btn>
              <img
                v-if="captcha !== null"
                :src="captcha.img"
                alt=""
                height="52"
              >
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
            >
              <v-text-field
                v-model="formFields.answer"
                placeholder="Enter Answer"
                type="text"
                maxlength="10"
                outlined
                dense
                class="md-4"
                :error-messages="formErrorFields.answerErrorMessage.length > 0 ? 'Invalid' : ''"
                @keydown="formError().remove('answer', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-center flex-wrap">
          <v-btn
            color="success"
            :loading="loading"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap">
          <span class="me-2">
            Already have an account?
          </span>
          <router-link :to="{name:'auth-login'}">
            Sign in instead
          </router-link>
        </v-card-text>
      </v-card>
    </div>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, watch } from '@vue/composition-api'
import { mask } from 'vue-the-mask'
import themeConfig from '@themeConfig'
import { mdiReload } from '@mdi/js'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import { useRouter } from '../../@core/utils'

export default {
  directives: { mask },
  components: { snackbar },

  setup() {
    const router = useRouter().route
    const provinceLoading = ref(false)
    const cityLoading = ref(false)
    const barangayLoading = ref(false)
    const provinces = ref([])
    const cities = ref([])
    const barangays = ref([])
    const loading = ref(false)
    const captcha = ref(null)
    const formFields = ref({
      membershipType: 'mlm',
      businessName: '',
      cdaRegistration: '',
      dtiOrSecRegistration: '',
      firstname: '',
      middlename: '',
      lastname: '',
      email: '',
      username: '',
      password: '',
      password_confirmation: '',
      mobileNumber: '',
      phoneNumber: '',
      address: '',
      sponsor: '',
      requestToCode: '',
      province: '',
      city: '',
      barangay: '',
      answer: '',
      accountType: 'INDIVIDUAL',
      requestTo: 'HEAD OFFICE',
    })
    const formErrorFields = ref({
      membershipTypeErrorMessage: '',
      businessNameErrorMessage: '',
      cdaRegistrationErrorMessage: '',
      dtiOrSecRegistrationErrorMessage: '',
      firstnameErrorMessage: '',
      middlenameErrorMessage: '',
      lastnameErrorMessage: '',
      emailErrorMessage: '',
      usernameErrorMessage: '',
      passwordErrorMessage: '',
      password_confirmationErrorMessage: '',
      mobileNumberErrorMessage: '',
      phoneNumberErrorMessage: '',
      addressErrorMessage: '',
      sponsorErrorMessage: '',
      requestToCodeErrorMessage: '',
      answerErrorMessage: '',
      provinceErrorMessage: '',
      cityErrorMessage: '',
      barangayErrorMessage: '',
      accountTypeErrorMessage: '',
      requestToErrorMessage: '',
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formFields.value = {
        membershipType: 'mlm',
        businessName: '',
        cdaRegistration: '',
        dtiOrSecRegistration: '',
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
        mobileNumber: '',
        phoneNumber: '',
        address: '',
        sponsor: '',
        requestToCode: '',
        province: '',
        city: '',
        barangay: '',
        accountType: 'INDIVIDUAL',
        requestTo: 'HEAD OFFICE',
        answer: ''
      }
    }

    watch(() => formFields.value.membershipType, val => {
      formFields.value.requestTo = 'HEAD OFFICE'
    })

    const getCaptcha = async () => {
      await get(`${process.env.VUE_APP_URI}/api/captcha/api/math`, captcha, null)
    }

    getCaptcha()

    const getProvince = async () => {
      await get(`${process.env.VUE_APP_URI}/api/area/provinces`, provinces, provinceLoading)
    }

    getProvince()

    const getCities = async provinceId => {
      await get(`${process.env.VUE_APP_URI}/api/area/cities/${provinceId}`, cities, cityLoading)
    }

    const getBarangay = async cityId => {
      await get(`${process.env.VUE_APP_URI}/api/area/barangays/${cityId}`, barangays, barangayLoading)
    }

    const provinceWasChange = () => {
      getCities(formFields.value.province)
      formError().remove('province', formErrorFields.value)
    }

    const cityWasChange = () => {
      getBarangay(formFields.value.city)
      formError().remove('city', formErrorFields.value)
    }

    const identifySponsorCode = async () => {
      if (router.value.params.sponsor !== undefined) {
        console.log(router.value)
        formFields.value.sponsor = router.value.params.sponsor
      }
    }

    identifySponsorCode()

    const submit = async () => {
      const formData = { ...formFields.value, key: captcha.value.key }

      const uri = `${process.env.VUE_APP_URI}/api/auth/member/register`
      await store(uri, loading, formData, formErrorFields)
      console.log(responseMessageStatus.value)
      if (responseMessageStatus.value == 'success') {
        reset()
      } else {
        formFields.value.answer = ''
        getCaptcha()
      }
    }

    return {
      router,
      getCaptcha,
      captcha,
      submit,
      loading,
      formFields,
      formError,
      formErrorFields,
      responseMessage,
      responseMessageStatus,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiReload,
      },

      provinceWasChange,
      cityWasChange,
      provinceLoading,
      cityLoading,
      barangayLoading,
      provinces,
      cities,
      barangays,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

<style scoped>
.app-logo {
  text-align: center !important;
  display: block;
}
.reg-bg {
  background-color: white;
}
.auth-wrapper, .auth-v1 {
  background-image: url("../../assets/images/others/farm.webp");
  background-size: cover;
  background-attachment: fixed;
  /* opacity: 0.6; */
}

.auth-wrapper::before {
    content: "";
    opacity: .5 !important;
    transition: opacity 0.6s ease;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #f6fde8 !important;
  }
</style>
