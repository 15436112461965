<script>

export default {
    props: { 
        isDialogVisible: Boolean
    },

    setup(props) {
        return {
            props
        }
    }
}
</script>

<template>
  <!-- Dialog -->
    <v-dialog
      v-model="props.isDialogVisible"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>