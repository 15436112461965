<template>
    <v-row class="m-0">
        <v-simple-table dense class="genealogy" responsive="sm" :style="{ 'background-color':'transparent', 'width' : '100%', 'overflow-x': 'auto'}">
            <tbody>
                <tr>
                    <td colspan="16" class="text-center py-0" v-if="placement!==null">
                        <h5>Binary Placement <br/> {{placement.position}} OF {{placement.name}} ({{placement.registration_code}})</h5>
                        <h5 class="mt-3">Sponsor <br/> {{sponsor.registration_code}} - {{sponsor.name}}</h5>
                    </td>
                </tr>
                <tr>
                    <td colspan="16" class="text-center" ref="img1">
                        <div class="thumbnail">
                            <v-img thumbnail class="mb-2 img-rounded"
                                alt="" :src="slot[1].image"
                            />
                            <div class="caption" v-if="slot[1].caption != ''" v-html="slot[1].caption"></div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="8" class="text-center" v-for="(n, index) in row2" :key="index">
                        <div class="thumbnail">
                            <template v-if="slot[n].route">
                                <v-img thumbnail class="mb-2 img-rounded" 
                                    style="cursor: pointer"
                                    alt="" :src="slot[n].image"
                                    v-if="slot[n].open"
                                    @click.native="setPlacement(slot[n].route)"
                                />
                                <router-link :to="slot[n].route" v-else>
                                    <v-img thumbnail class="mb-2 img-rounded"
                                        style="border-radius:5px; height:70px; width:70px;"
                                        alt="" :src="slot[n].image"
                                    />
                                    <div class="caption" v-if="slot[n].caption != ''" v-html="slot[n].caption"></div>
                                </router-link>
                            </template>
                            <template v-else>
                                <v-img thumbnail 
                                    alt="" :src="slot[n].image"
                                    style="border-radius:5px; height:70px; width:70px;"
                                />
                                <div class="caption" v-if="slot[n].caption != ''" v-html="slot[n].caption"></div>
                            </template>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4" class="text-center mt-8" v-for="(n, index) in row3" :key="index">
                        <div class="thumbnail">
                            <template v-if="slot[n].route">
                                <v-img thumbnail class="mb-2 img-rounded" 
                                    style="cursor: pointer"
                                    alt="" :src="slot[n].image"
                                    v-if="slot[n].open"
                                    @click.native="setPlacement(slot[n].route)"
                                />
                                <router-link :to="slot[n].route" v-else>
                                    <v-img thumbnail 
                                        style="border-radius:5px; height:70px; width:70px;"
                                        alt="" 
                                        :src="slot[n].image"
                                    />
                                    <div class="caption" v-if="slot[n].caption != ''" v-html="slot[n].caption"></div>
                                </router-link>
                            </template>
                            <template v-else>
                                <v-img thumbnail 
                                    alt="" :src="slot[n].image"
                                    style="border-radius:5px; height:70px; width:70px;"
                                />
                                <div class="caption" v-if="slot[n].caption != ''" v-html="slot[n].caption"></div>
                            </template>
                        </div>
                    </td>
                </tr>
                <tr v-if="screenWidth > 812">
                    <td colspan="2" class="text-center" v-for="(n, index) in row4" :key="index">
                        <div class="thumbnail">
                            <template v-if="slot[n].route">
                                <v-img thumbnail class="mb-2 img-rounded" 
                                    style="cursor: pointer"
                                    :src="slot[n].image"
                                    v-if="slot[n].open"
                                    @click.native="setPlacement(slot[n].route)"
                                />
                                <router-link :to="slot[n].route" v-else>
                                    <v-img thumbnail class="mb-2 img-rounded" 
                                        style="border-radius:5px; height:70px; width:70px;"
                                        alt="" :src="slot[n].image"
                                    />
                                    <div class="caption" v-if="slot[n].caption != ''" v-html="slot[n].caption"></div>
                                </router-link>
                            </template>
                            <template v-else>
                                <v-img thumbnail class="mb-2 img-rounded" 
                                        alt="" :src="slot[n].image"
                                    />
                                <div class="caption" v-if="slot[n].caption != ''" v-html="slot[n].caption"></div>
                            </template>
                        </div>
                    </td>
                </tr>
                <tr v-if="screenWidth > 1366">
                    <td class="text-center pl-0 pr-0 pt-6 mb-8" v-for="(n, index) in row5" :key="index">
                        <div class="thumbnail">
                            <template v-if="slot[n].route">
                                <v-img thumbnail class="mb-2 img-rounded" 
                                    style="cursor: pointer"
                                    alt="" :src="slot[n].image"
                                    v-if="slot[n].open"
                                    @click.native="setPlacement(slot[n].route)"
                                />
                                <router-link :to="slot[n].route" v-else>
                                    <v-img thumbnail class="mb-2 img-rounded" 
                                        style="border-radius:5px; height:70px; width:70px;"
                                        :src="slot[n].image"
                                    />
                                    <div class="caption" v-if="slot[n].caption != ''" v-html="slot[n].caption"></div>
                                </router-link>
                            </template>
                            <template v-else>
                                <v-img thumbnail class="mb-2 img-rounded" 
                                        alt="" :src="slot[n].image"
                                    />
                                <div class="caption" v-if="slot[n].caption != ''" v-html="slot[n].caption"></div>
                            </template>
                        </div>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <Loader :isDialogVisible="loading"/>
        <ConfirmDialog 
            :show="confirm"
            @no="confirm=false"
            @yes="submitPlacement">
            <template v-slot:message>Are you sure want to place {{store.state.network.account_id}} to {{selectedPosition == 'L' ? 'LEFT' : 'RIGHT'}} of {{selectedPlacement}}</template>
        </ConfirmDialog>

        <Snackbar
            v-if="responseMessage !== undefined"
            :show="responseMessage !== undefined"
            :text="responseMessage"
            :color="responseMessageStatus"
            @close="responseMessage=undefined"
        ></Snackbar>
    </v-row>
</template>

<script>
	import fn from '@/functions/fn'
    import { ref, watch, watchEffect } from '@vue/composition-api'
    import Loader from '../../../components/Loading.vue'
    import ConfirmDialog from '../../../components/ConfirmDialog.vue'
    import { useRouter } from '@core/utils'
    import axios from 'axios'
    import store from '../../../store'
    import Snackbar from '@/components/SnackBar.vue'

    export default {
        metaInfo: {
            title: 'Binary Genealogy',
        },
        props: {
            account: null
        },

        components: {
            Loader,
            ConfirmDialog,
            Snackbar,
        },

        setup(props) {
            const responseMessageStatus = ref()
            const responseMessage = ref()
            const confirm = ref(false)
            const selectedPosition = ref()
            const selectedPlacement = ref()
            const loading = ref(false)
            const sponsor = ref("")
            const placement = ref("")
            const downlineId = ref("")
            const downlines = ref([])
            const slot = ref({
                1: {number: 1, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0 },
                2: {number: 2, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 1 }, 
                3: {number: 3, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 1  },
                4: {number: 4, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 2  },
                5: {number: 5, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 2  },
                6: {number: 6, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 3  },
                7: {number: 7, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 3  },
                8: {number: 8, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 4  },
                9: {number: 9, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 4  },
                10: {number: 10, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 5  },
                11: {number: 11, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 5  },
                12: {number: 12, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 6  },
                13: {number: 13, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 6  },
                14: {number: 14, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 7  },
                15: {number: 15, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 7  },
                16: {number: 16, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 8  },
                17: {number: 17, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 8  },
                18: {number: 18, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 9  },
                19: {number: 19, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 9  },
                20: {number: 20, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 10  },
                21: {number: 21, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 10  },
                22: {number: 22, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 11  },
                23: {number: 23, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 11  },
                24: {number: 24, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 12  },
                25: {number: 25, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 12  },
                26: {number: 26, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 13  },
                27: {number: 27, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 13  },
                28: {number: 28, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 14  },
                29: {number: 29, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 14  },
                30: {number: 30, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 15  },
                31: {number: 31, image: require("@/assets/images/avatars/19.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 15  }
            })
            const defaultImage = require("@/assets/images/avatars/3.png")
            const availableSlot = require("@/assets/images/avatars/18.jpg")
            const unavailableSlot = require("@/assets/images/avatars/19.jpg")
            const screenWidth = window.screen.width
            
            const { route, router } = useRouter()

            const getBinaryDownlines = async () => {
                loading.value = true
                try {
                    const uri = downlineId.value == "" ? `${process.env.VUE_APP_URI}/api/member/network/binary/${props.account}` : `${process.env.VUE_APP_URI}/api/member/network/binary/${props.account}/${downlineId.value}` 
                    const response = await axios.get(uri)
                    const data = response.data.structure
                    placement.value = response.data.placement
                    sponsor.value = response.data.sponsor

                    if(data.length == 0) {
                        router.push('/not-found')
                    }
                    downlines.value = data
                    clearSlots()
                    plotSlot()
                    loading.value = false
                } catch(error) {
                    loading.value = false
                }
            }

            const plotSlot = () => {
                let parseDownline = []
                for(const downlines of downlines.value) {
                    for(const downlineInfo of downlines) {
                        let color = 'primary'
                        
                        slot.value[downlineInfo.number].image = downlineInfo.member.image != null ? 
                            (domainUrl + "/image/" + fn.base64_encode(downlineInfo.member.image)) : defaultImage

                        slot.value[downlineInfo.number].caption = (downlineInfo.member.firstname !== '' ? downlineInfo.member.firstname.toUpperCase() : downlineInfo.member.name.toUpperCase()) +
                                '<br/><span style="font-size:7px !important;" class="text-xs p-1 badge badge-' + color + '">' + downlineInfo.registration_code + '</span>'

                        slot.value[downlineInfo.number].slotId = downlineInfo.id
                        parseDownline.push(downlineInfo)
                    }
                }

                    
                Object.entries(slot.value).map(([key, slotx]) => {
                    // check slot if filled
                    const slotIndex = parseDownline.findIndex(val => { return val.number === slotx.number})
                    if(slotIndex >= 0) {
                        slot.value[key] = Object.assign(slotx, {open: false, route: {
                                name: 'genealogy-binary', params: {
                                    downlineId: parseDownline[slotIndex].id
                                }
                            }
                        })
                    } else {
                        // check placement if not open slot
                        if(slotx.placementSlotNumber === undefined) {
                            slot.value[key] = Object.assign(slotx, {open: false})
                        } else {
                            const placementIndex = parseDownline.findIndex(val => { return val.number === slotx.placementSlotNumber})
                            
                            if(placementIndex < 0) {
                                slot.value[key] = Object.assign(slotx, {open: false, image: unavailableSlot})
                            } else {
                                slot.value[key] = Object.assign(slotx, {
                                    open: true, 
                                    route: {
                                        name: 'genealogy-binary', //network-register 
                                        params:{
                                            sponsorId: parseDownline[placementIndex].registration_code,
                                            placementId: parseDownline[placementIndex].registration_code,
                                            position: slotx.position
                                        }
                                    }
                                })
                            }
                        }
                    }
                })
            }

            const clearSlots = () => {
                slot.value = {
                    1: {number: 1, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0 },
                    2: {number: 2, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 1 }, 
                    3: {number: 3, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 1  },
                    4: {number: 4, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 2  },
                    5: {number: 5, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 2  },
                    6: {number: 6, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 3  },
                    7: {number: 7, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 3  },
                    8: {number: 8, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 4  },
                    9: {number: 9, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 4  },
                    10: {number: 10, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 5  },
                    11: {number: 11, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 5  },
                    12: {number: 12, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 6  },
                    13: {number: 13, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 6  },
                    14: {number: 14, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 7  },
                    15: {number: 15, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 7  },
                    16: {number: 16, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 8  },
                    17: {number: 17, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 8  },
                    18: {number: 18, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 9  },
                    19: {number: 19, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 9  },
                    20: {number: 20, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 10  },
                    21: {number: 21, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 10  },
                    22: {number: 22, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 11  },
                    23: {number: 23, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 11  },
                    24: {number: 24, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 12  },
                    25: {number: 25, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 12  },
                    26: {number: 26, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 13  },
                    27: {number: 27, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 13  },
                    28: {number: 28, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 14  },
                    29: {number: 29, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 14  },
                    30: {number: 30, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'L', placementSlotNumber: 15  },
                    31: {number: 31, image: require("@/assets/images/avatars/18.jpg"), caption: "", slotId: 0, position: 'R', placementSlotNumber: 15  }
                }
            }

            watch( () => route.value.params.downlineId, async e => {
                downlineId.value = e !== undefined ? e : ""
                await getBinaryDownlines() 
            })

            watchEffect( async () => {
                downlineId.value = route.value.params.downlineId !== undefined ? route.value.params.downlineId : ""
                await getBinaryDownlines() 
            })

            const setPlacement = (url) => {
                if(store.state.network.account_id !== '') {
                    selectedPosition.value = url.params.position
                    selectedPlacement.value = url.params.placementId
                    confirm.value = true
                } else {
                    router.push(url)
                }
            }

            const submitPlacement = async () => {
                loading.value = true
                try {
                    const response = await axios.post(`${process.env.VUE_APP_URI}/api/member/network/set-placement/${store.state.network.account_id}/${selectedPlacement.value}/${selectedPosition.value}`)
                    await getBinaryDownlines() 
                    responseMessage.value = response.data.message
                    responseMessageStatus.value = 'success'

                    store.dispatch('setId', '')
                    confirm.value = false
                    loading.value = false
                } catch(error) {
                    responseMessage.value = error.response.data.message
                    responseMessageStatus.value = 'error'
                    confirm.value = false
                    loading.value = false
                }
            }

            return {
                props,
                loading,
                defaultImage,
                availableSlot,
                unavailableSlot,
                screenWidth,
                row1: [1],
                row2: [2,3],
                row3: [4,5,6,7],
                row4: [8,9,10,11,12,13,14,15],
                row5: [16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
                slot,
                placement,
                downlineId,
                downlines,
                sponsor,
                setPlacement,
                confirm,
                selectedPosition,
                selectedPlacement,
                store,
                submitPlacement,
                responseMessage,
                responseMessageStatus
            }
        }
    }
</script>

<style  scoped>
    .genealogy tr:hover {
        background: transparent !important;
    }
    .genealogy tr td {
        border: none !important;
        padding-bottom: 10px !important;
    }

    .thumbnail {
        margin: 0 auto;
        position: relative;
        width: 80px;
        background-color: transparent !important;
    }

    .caption {
        padding: 3px;
        background-color: rgb(195, 197, 162);
        opacity: .9;
        position: absolute;
        top: 70%;
        /* left: -5px !important; */
        margin-left: auto !important;
        margin-right: auto !important;
        color: black;
        white-space: pre-line;
        font-size: 7px !important;
        font-weight: bold;
        width: 80px;
        max-width: 90px;
        border-radius: 5px;
    }

    .img-thumbnail {
        border-radius: 20px !important;
        width: 80px !important;
        height: 80px !important;
        border: none !important;
    }

    td {
        padding-bottom: 20px;
        padding-top: 20px;
    }
</style>