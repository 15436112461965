<template>
    <div class="main-content">
        <!-- <breadcumb :page="pageTitle" /> -->
        <v-card class="py-3 px-3">
            <v-row v-if="accounts.length > 1">
                <v-col cols="12" md="4" lg="3" sm="12" class="mb-1">
                    <v-autocomplete
                        v-model="account"
                        :items="accounts"
                        @change="fetchWasClick"
                        item-value="value"
                        item-text="text"
                    />
                </v-col>
            </v-row>
            <BinaryTreeview :account="account"/>
            <v-card-title v-if="store.state.network.account_id !== ''">
                Browsing Placement For: {{store.state.network.account_id}}
                <v-btn 
                    class="ml-2"
                    x-small
                    text
                    color="primary"
                    @click="store.dispatch('setId', '')">clear</v-btn>
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
    import { watchEffect, ref } from '@vue/composition-api';
    import BinaryTreeview from './components/BinaryTreeview.vue';
    import { useRouter } from '@core/utils'
    import store from '../../store'

    export default {
        metaInfo: {
			title: "Binary",
		},

        components: {
            BinaryTreeview
        },

        setup() {
            const { route, router } = useRouter()
            const account = ref("")
            const accounts = ref([])

            watchEffect(() => {
                const user = store.state.user.info
                
                let accts = []
                for(const mc of user.member_account) {
                    accts.push({value: mc.id, text: mc.registration_code});
                }

                accounts.value = accts
                account.value = user.member_account[0].id
            })

            const fetchWasClick = async () => {
                if(route.params.downlineId != account.value) {
                    router.push({name: 'genealogy-binary', params: {downlineId: account.value}});
                }
            }

            return {
                pageTitle: "Binary Treeview",
                account,
                accounts,
                store,
                fetchWasClick,
            }
        }

    }

</script>

<style  scoped>
    .app-footer {
        margin-top: 2rem;
        background: #eee;
        padding: 1.25rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: none;
    }
    
    .row {
        margin: 0;
    }
</style>