import {
  mdiAccount,
  mdiCreditCard,
  mdiHomeOutline,
  mdiWallet,
  mdiInbox,
  mdiAccountGroupOutline,
  mdiArrowUp,
  mdiGroup
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
  },
  {
    title: 'Genealogy',
    icon: mdiAccountGroupOutline,
    children: [
      {
        title: 'Binary',
        to: 'genealogy-binary',
      },
      {
        title: 'Unilevel',
        to: 'genealogy-unilevel',
      },
      {
        title: 'CPP',
        to: 'genealogy-cpp',
      }
    ]
  },
  {
    title: 'Earnings',
    icon: mdiCreditCard,
    children: [
      {
        title: 'Matching Incentive',
        to: 'matching-incentive',
      },
      {
        title: 'Cash Back',
        to: 'cash-backs',
      },
      {
        title: 'Referral Bonus',
        to: 'earnings-referral',
      },
      // {
      //   title: 'Personal Rebates',
      //   to: 'earnings-personal-rebates',
      // },
      {
        title: 'Unilevel Bonus',
        to: 'earnings-unilevel',
      },
      {
        title: 'CPP Bonus',
        to: 'earnings-cpp',
      },
    ],
  },
  {
    title: 'Maintenance',
    icon: mdiInbox,
    children: [
      {
        title: 'Assignment',
        to: 'maintenance-assignment',
      },
      {
        title: 'History',
        to: 'maintenance-history',
      },
    ],
  },
  {
    title: 'Wallet',
    icon: mdiWallet,
    to: 'wallet',
  },
  {
    title: 'For Placement',
    icon: mdiAccountGroupOutline,
    to: 'for-placement',
  },
  {
    title: 'Add Account',
    icon: mdiAccount,
    to: 'add-account',
  },
  {
    title: 'Upgrade Account',
    icon: mdiArrowUp,
    to: 'upgrade-account',
  },
]
