<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          <slot name="title"></slot>
        </v-card-title>
        <v-card-text>
          <slot name="message"></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="no"
          >
            {{ noText }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="yes"
          >
            {{ yesText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, watch, watchEffect } from '@vue/composition-api'

export default {
  props: {
    show: Boolean,
    yesButtonText: String,
    noButtonText: String,
  },

  setup(props, { emit }) {
    const dialog = ref(false)
    const noText = ref('No')
    const yesText = ref('Yes')

    const yes = () => {
      emit('yes')
    }

    const no = () => {
      emit('no')
    }

    watchEffect(() => {
      console.log(props.yesButtonText)
      if (props.yesButtonText !== undefined) yesText.value = props.yesButtonText
      if (props.noButtonText !== undefined) noText.value = props.noButtonText
    })

    watch(() => props.show, show => {
      dialog.value = show
    })

    return {
      yesText,
      noText,
      yes,
      no,
      dialog,
    }
  },
}
</script>
