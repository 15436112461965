<template>
  <v-col
    cols="12"
    lg="8"
    offset-md="2"
  >
    <v-card>
      <v-card-title>Register Additional Account</v-card-title>
      <v-card-text>
        <v-form>
          <v-row class="mt-0 pt-0">
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <v-radio-group
                v-model="formFields.membershipType"
                row
                :error-messages="formErrorFields.membershipTypeErrorMessage"
              >
                <v-radio
                  label="CPP"
                  value="cpp"
                  @click="formError().remove('membershipType', formErrorFields)"
                ></v-radio>
                <v-radio
                  label="MLM"
                  value="mlm"
                  @click="formError().remove('membershipType', formErrorFields)"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              lg="3"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="sponsorType"
                :items="['Personal Account','Others Account']"
                dense
                auto-select-first
                label="Sponsor"
                :class="formErrorFields.requestToErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.requestToErrorMessage"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              :lg="sponsorType === 'Personal Account' ? 9 : 3"
              class="py-0 my-0"
            >
              <v-text-field
                v-if="sponsorType === 'Others Account'"
                v-model="formFields.sponsor"
                label="Sponsor Code"
                outlined
                dense
                type="text"
                :class="formErrorFields.sponsorErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.sponsorErrorMessage"
                @blur="getSponsorName"
                @keydown="formError().remove('sponsor', formErrorFields)"
              ></v-text-field>
              <v-autocomplete
                v-else
                v-model="formFields.sponsor"
                :items="accounts"
                dense
                item-text="registration_code"
                auto-select-first
                label="Select Sponsor Code"
                :class="formErrorFields.sponsorErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.sponsorErrorMessage"
                outlined
              />
            </v-col>
            <v-col
              v-if="sponsorType === 'Others Account'"
              cols="12"
              lg="6"
              class="py-0 my-0"
            >
              <v-text-field
                v-model="sponsorName"
                label="Sponsor Name"
                outlined
                dense
                disabled
                class="black--text"
                type="text"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="formFields.requestTo"
                :items="['STOCKIST','BRANCH','HEAD OFFICE']"
                dense
                clearable
                auto-select-first
                label="Request to"
                :class="formErrorFields.requestToErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.requestToErrorMessage"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0 my-0"
              v-if="formFields.requestTo != 'HEAD OFFICE'"
            >
              <v-text-field
                v-model="formFields.requestToCode"
                :label="`${formFields.requestTo} CODE`"
                outlined
                dense
                type="text"
                :class="formErrorFields.requestToCodeErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.requestToCodeErrorMessage"
                @keydown="formError().remove('requestToCode', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="success"
          :loading="loading"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-col>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mask } from 'vue-the-mask'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import { useRouter } from '../@core/utils'
import storeState from '../store'

export default {
  directives: { mask },
  components: { snackbar },

  setup(_, ctx) {
    const { route } = useRouter()
    const loading = ref(false)
    const accounts = ref(storeState.state.user.info.member_account)
    const sponsorType = ref('Personal Account')
    const sponsorCode = ref('')
    const sponsorName = ref('')
    const formFields = ref({
      membershipType: 'mlm',
      sponsor: '',
      requestTo: 'BRANCH',
      requestToCode: '',
    })
    const formErrorFields = ref({
      membershipTypeErrorMessage: '',
      sponsorErrorMessage: '',
      requestToCodeErrorMessage: '',
      requestToErrorMessage: '',
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formFields.value = {
        membershipType: 'mlm',
        sponsor: '',
        sponsorName: '',
        requestToCode: '',
        requestTo: 'BRANCH'
      }
    }

    const getSponsorName = async () => {
      await get(`${process.env.VUE_APP_URI}/api/member/network/sponsor/${formFields.value.sponsor}`, sponsorName, loading)
    }

    const getSponsorCode = async id => {
      await get(`${process.env.VUE_APP_URI}/api/member/network/sponsor/${id}/code`, sponsorCode, loading)
    }

    const identifySponsorCode = async () => {
      if (route.value.params.sponsor !== undefined) {
        await getSponsorCode(route.value.params.sponsor)
        formFields.value.sponsor = sponsorCode.value
        await getSponsorName()
      }
    }

    watch(() => formFields.value.membershipType, val => {
      formFields.value.sponsor = ''
      if(val === 'cpp') {
        accounts.value = storeState.state.user.info.member_cpp_account
      } else {
        accounts.value = storeState.state.user.info.member_account
      }

      if(accounts.value.length > 0) {
        formFields.value.sponsor = accounts.value[0].registration_code
      }
    })

    identifySponsorCode()

    const submit = async () => {
      const formData = formFields.value

      const uri = `${process.env.VUE_APP_URI}/api/member/additional-account-registration`
      await store(uri, loading, formData, formErrorFields)
      if (responseMessageStatus.value === 'success') {
        reset()
      }
    }

    return {
      getSponsorName,
      submit,
      loading,
      accounts,
      sponsorType,
      sponsorName,
      formFields,
      formError,
      formErrorFields,
      responseMessage,
      responseMessageStatus,
    }
  },
}
</script>
