<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
      lg="3"
    >
      <v-card :loading="loading" color="warning">
        <statistics-card-vertical
          color="success"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(mathcingIncentiveBonus)"
          stat-title="Total Matching Bonus"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="3"
      lg="3"
    >
      <v-card :loading="loading" color="warning">
        <statistics-card-vertical
          color="success"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(referral)"
          stat-title="Total Referral Bonus"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="3"
      lg="3"
    >
      <v-card :loading="loading" color="info">
        <statistics-card-vertical
          color="info"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(unilevel)"
          stat-title="Total Unilevel Bonus"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="3"
      lg="3"
    >
      <v-card :loading="loading" color="info">
        <statistics-card-vertical
          color="success"
          :icon="mdiCurrencyUsd"
          :statistics="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(cash_back)"
          stat-title="Total Cash Back"
          subtitle="as of today"
        ></statistics-card-vertical>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrendingUp, mdiCurrencyUsd, mdiCheck, mdiChartTimelineVariant } from '@mdi/js'
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

export default {
  props: {
    retail_profit: null,
    cash_back: null,
    referral: null,
    mathcingIncentiveBonus: null,
    unilevel: null,
    loading: Boolean,
  },
  components: {
    StatisticsCardVertical,
  },
  setup() {
    return {
      mdiCurrencyUsd,
    }
  }
}
</script>
