<template>
  <v-card class="elevation-1">
    <v-card-title>
      Unilevel Bonus
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <v-select
            v-model="account"
            :items="accounts"
            :item-text="'text'"
            :item-key="'id'"
            label="Account"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <v-select
            v-model="cutoff"
            :items="cutoffs"
            :item-text="'cutoff'"
            :item-key="'cutoff'"
            label="Cutoff"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
          lg="4"
          offset-md="2"
          offset-lg="2"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.gross`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.gross) }}</span>
      </template>
      <template #[`item.wtax`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.wtax) }}</span>
      </template>
      <template #[`item.net`]="{ item }">
        <span>{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(item.net) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import get from '@/composables/get'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'
import store from '../../store'
import fn from '@/functions/fn'

export default {
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Member ID', align: 'start', value: 'registration_code' },
      { text: 'Member Name', align: 'start', value: 'name' },
      { text: 'Product', align: 'start', value: 'product_description' },
      { text: 'Date Purchased', align: 'start', value: 'date_purchased' },
      { text: 'Level', align: 'start', value: 'level' },
      { text: 'Gross', align: 'end', value: 'gross' },
      { text: 'WTax', align: 'end', value: 'wtax' },
      { text: 'Net', align: 'end', value: 'net' },
    ])
    const cutoff = ref('')
    const cutoffs = ref([])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const account = ref('')
    const accounts = ref([])

    const listUri = `${process.env.VUE_APP_URI}/api/member/earnings/unilevel`

    const getCutoff = async () => {
      await get(`${process.env.VUE_APP_URI}/api/member/earnings/unilevel-cutoff`, cutoffs, null)
      // cutoff.value = cutoffs.value[0]
    }

    getCutoff()

    const parseAccount = () => {
      const user = store.state.user.info
      
      for(const row of user.member_account) {
        accounts.value.push({value: row.id, text: row.registration_code});
      }

      account.value = user.member_account[0].id
      // getData(listUri, items, totalItems, loading, options, search, fn.base64_encode({account: account.value, cutoff: cutoff.value}))
    }

    parseAccount()

    watch(account, () => {
      if(cutoff.value !== '') {
        getData(listUri, items, totalItems, loading, options, search, fn.base64_encode({account: account.value, cutoff: cutoff.value}))
      }
    })

    watch(cutoff, () => {
      if(account.value !== '') {
        options.value.page = 1
        getData(listUri, items, totalItems, loading, options, search, fn.base64_encode({account: account.value, cutoff: cutoff.value}))
      }
    })

    watch(options, () => {
      if(cutoff.value !== '' && account.value !== '') {
        getData(listUri, items, totalItems, loading, options, search, fn.base64_encode({account: account.value, cutoff: cutoff.value}))
      }
    })

    watch(searchTable, () => {
      if(cutoff.value !== '' && account.value !== '') {
        options.value.page = 1
        getData(listUri, items, totalItems, loading, options, search, fn.base64_encode({account: account.value, cutoff: cutoff.value}))
      }
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      accounts,
      account,
      cutoff,
      cutoffs,
    }
  },

  components: {
    snackbar,
  },
}
</script>
