<template>
  <v-card
    class="elevation-1"
    :loading="loading"
  >
    <v-card-title class="py-9">
      Wallet
    </v-card-title>
    <v-card-text>
      <v-form>
        <!-- employee -->
        <p class="text-xl-h4 text-h4 text-center">
          ₱ <span  style="color:red">{{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(balance) }}</span>
        </p>

        <v-row class="mt-0 pt-0 mb-4">
          <v-col
            cols="12"
            class="pt-0 my-0"
          >
            <v-btn-toggle
              v-model="source"
              variant="outlined"
              color="primary"
              class="ml-2"
              divided
            >
              <v-btn block>MLM</v-btn>
              <v-btn block>CPP</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <!-- Accounts -->
        <v-row>
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="account"
                :items="accounts"
                item-text="registration_code"
                item-value="registration_code"
                dense
                auto-select-first
                label="Account"
                :class="formErrorFields.accountErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.accountErrorMessage"
                outlined
              />
            </v-col>
        </v-row>

        <v-row class="mt-0 pt-0">
          <v-col
            cols="12"
            class="pt-0 my-0"
          >
            <v-autocomplete
              v-model="mode"
              outlined
              dense
              clearable
              label="Mode of payout"
              item-text="title"
              item-value="id"
              :items="['CHECK','GCASH','BPI', 'BDO']"
              :class="formErrorFields.modeErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.modeErrorMessage"
              :disabled="balance===0"
              @change="formError().remove('mode', formErrorFields)"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="pt-0 mt-0">
          <v-col
            cols="12"
            class="pt-0 mt-0"
          >
            <!-- Amount -->
            <v-text-field
              v-model="amount"
              label="Amount"
              outlined
              dense
              type="number"
              placeholder="0.00"
              :class="formErrorFields.amountErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.amountErrorMessage"
              :disabled="balance===0"
              @keydown="formError().remove('amount', formErrorFields)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-textarea
          v-model="remarks"
          outlined
          dense
          clearable
          label="Remarks"
          :class="formErrorFields.remarksErrorMessage.length > 0 ? 'mb-3' : ''"
          rows="3"
          :error-messages="formErrorFields.remarksErrorMessage"
          :disabled="balance===0"
          @keydown="formError().remove('remarks', formErrorFields)"
        ></v-textarea>

        <v-alert
          color="error"
          class="caption"
          dark
          border="left"
          prominent
        >
          Please be advised that we will be deducting a 3% service charge to the total amount.
        </v-alert>

        <v-row>
          <v-col cols="12">
            <v-subheader>E-Savings (%)</v-subheader>
            <v-slider
              color="error"
              class="mt-5"
              v-model="esavings"
              thumb-label="always"
              max="10"
              min="2"
            ></v-slider>
          </v-col>
        </v-row>

        <v-btn
          color="success"
          :disabled="balance===0"
          :loading="loading"
          @click="submit"
        >
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="balance===0"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import storeState from '../../store'

export default {

  components: {
    snackbar,
  },
  props: ['data'],
  setup(props, { emit }) {
    const esavings = ref(2)
    const balance = ref(0)
    const loading = ref(false)
    const source = ref(0)
    const message = ref('')
    const mode = ref('')
    const amount = ref(null)
    const remarks = ref('')
    const accounts = ref([])
    const account = ref('')
    const formErrorFields = ref({
      modeErrorMessage: '',
      amountErrorMessage: '',
      remarksErrorMessage: '',
      accountErrorMessage: '',
    })

    watch(() => props.data, () => {
      mode.value = props.data.deduction_id
      amount.value = props.data.amount.replace(',', '')
      remarks.value = props.data.remarks
    })

    watch(() => source.value, val => {
      getAccounts(val)
    })

    watch(() => account.value, val => {
      if(val.length) getBalance(val)
    })

    const {
      store,
      responseMessage,
      responseMessageStatus,  
    } = crud()

    const getAccounts = async (val) => {
      account.value = ''
      accounts.value = []
      balance.value = 0
      
      if(val === 1) {
        accounts.value = storeState.state.user.info.member_cpp_account
      } else {
        accounts.value = storeState.state.user.info.member_account
      }

      if(accounts.value.length > 0) {
        account.value = accounts.value[0].registration_code
      }
    }

    const getBalance = async (accountId) => {
      balance.value = 0
      await get(`${process.env.VUE_APP_URI}/api/member/wallet/${source.value === 1 ? 'cpp' : 'mlm'}/balance/${accountId}`, balance, loading)
    }

    const reset = () => {
      mode.value = ''
      amount.value = null
      remarks.value = ''
    }

    const submit = async () => {
      const formData = {
        mode: mode.value,
        account: account.value,
        amount: amount.value,
        esavings: esavings.value,
        remarks: remarks.value,
        source: source.value === 1 ? 'cpp' : 'mlm'
      }

      const uri = `${process.env.VUE_APP_URI}/api/member/wallet/withdraw`
      await store(uri, loading, formData, formErrorFields)

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        await getBalance(account.value)
        emit('updateUniqueID')
        reset()
      }
    }

    getAccounts(source.value)
    
    return {
      accounts,
      account,
      source,
      mode,
      amount,
      remarks,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      balance,
      esavings,
    }
  },
}
</script>
