<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="6"
        xl="6"
      >
        <dashboard-welcome></dashboard-welcome>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="12"
        xl="6"
      >
        <dashboard-balance
          :e_savings="data.e_savings"
          :wallet_balance="data.wallet_balance"
          :withdrawals="data.withdrawals"
          :loading="loading"
          >
        </dashboard-balance>
      </v-col>
    </v-row>
    <dashboard-earnings
      :referral="data.referral"
      :mathcingIncentiveBonus="data.match"
      :unilevel="data.unilevel"
      :retail_profit="data.retail_profit"
        :cash_back="data.cashback"
      :loading="loading"
      >
    </dashboard-earnings>
    <dashboard-earnings-2
        :indirect="data.indirect"
        :stair_step="data.stair_step"
        :global_pool="data.global_pool"
        :profit_share="data.profit_share"
        :loading="loading"
      >
      </dashboard-earnings-2>
    <dashboard-account-profit-breakdown></dashboard-account-profit-breakdown>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline

import { ref } from '@vue/composition-api'
import get from '@/composables/get'

// demos
import DashboardWelcome from './DashboardWelcome.vue'
import DashboardEarnings from './DashboardEarnings.vue'
import DashboardEarnings2 from './DashboardEarnings2.vue'
import DashboardBalance from './DashboardBalance.vue'
import DashboardAccountProfitBreakdown from './DashboardAccountProfitBreakdown.vue'

export default {
  metaInfo: {
    title: 'Dashboard',
  },
  components: {
    DashboardWelcome,
    DashboardEarnings,
    DashboardEarnings2,
    DashboardBalance,
    DashboardAccountProfitBreakdown,
  },

  setup() {
    const loading = ref(false)
    const data = ref({
      e_savings: 0,
      referral: 0,
      unilevel: 0,
      withdrawals: 0,
      wallet_balance: 0,
      retail_profit: 0,
      cashback: 0,
      indirect: 0,
      stair_step: 0,
      global_pool: 0,
      profit_share: 0,
    })

    const getStatistics = async () => {
      await get(`${process.env.VUE_APP_URI}/api/member/dashboard/index`, data, loading)
    }

    getStatistics()

    return {
      loading,
      data,
    }
  },
}
</script>
