<template>
 <v-row>
    <v-col
      cols="12"
    >
      <v-card class="elevation-1">
        <v-card-title>
          For Placements
          <v-row>
            <v-col
              cols="12"
              md="4"
              lg="4"
              offset-md="8"
              offset-lg="8"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="mb-2"
                @keypress.enter="searchTable=search"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="searchTable"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          dense
        >
          <template #[`item.action`]="{ item }">
            <v-btn
              x-small
              text
              color="primary"
              @click="browsePlacement(item)"
            >
              Browse Placement
            </v-btn>
            <!-- <v-btn
              x-small
              text
              color="primary"
              @click="autoPlace(item, 'L')"
            >
              Auto Place (L)
            </v-btn>
            <v-btn
              x-small
              text
              color="primary"
              @click="autoPlace(item, 'R')"
            >
              Auto Place (R)
            </v-btn> -->
          </template>
        </v-data-table>

        <snackbar
          v-if="responseMessage.length > 0"
          :show="responseMessage.length > 0"
          :text="responseMessage"
          :color="responseMessageStatus"
          @close="responseMessage=''"
        />
      </v-card>
    </v-col>
 </v-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import store from '../../store'
import { useRouter } from '@core/utils'

export default {

  components: {
    snackbar,
  },
  props: ['uniqueID'],
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Full Name', align: 'start', value: 'member_name' },
      { text: 'Entry Type', align: 'start', value: 'entry_type' },
      { text: 'ID Number', align: 'start', value: 'registration_code' },
      { text: 'Date/Time Registered', align: 'start', value: 'created_at' },
      { text: 'Date/Time Activated', align: 'start', value: 'activated_at' },
      { text: 'Action', align: 'center', value: 'action' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const { router } = useRouter()

    const { responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/member/for-placements`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    const browsePlacement = (account) => {
      store.dispatch('setId', account.registration_code)
      router.push({name:'genealogy-binary'})

    }

    const autoPlace = (account, group) => {

    }

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      snackbar,
      responseMessageStatus,
      responseMessage,

      browsePlacement,
      autoPlace
    }
  },
}
</script>
